/*
 * custom variables
 */

// logo
$logo: url('../images/static/plugin-service.svg');

// logo dimentions; height & width
$logo-size-big: 80px 250px;
$logo-size-normal: 70px 225px;
$logo-size-small: 60px 200px;


/*
 * existing variables
 * see "xp-lib-brand/.../assets/styles/_variables.scss" for other var's
 */

// colors
$primary-color: #333333;
$secondary-color: #b0b0b0;
$tertiary-color: #eee;

// logo - header
$logo-header: $logo;
$logo-header-size-desktop: $logo-size-big;
$logo-header-size-tablet: $logo-size-normal;
$logo-header-size-mobile: $logo-size-small;
// logo - footer
$logo-footer: $logo;
$logo-footer-size: $logo-size-big;

// footer
$footer-border-color: $secondary-color;
$footer-blue-text: $tertiary-color;
$footer-border: $secondary-color 1px solid;
