/* stylelint-disable */
$primary-color: #00205B !default;
$secondary-color: #D6001C !default;

// Colors
$title-color: $primary-color !default;
$text-color: $primary-color !default;
$link-color: $primary-color !default;
$error-color: $secondary-color !default;
$link-text-decoration: none !default;
$link-color-hover: $link-color !default;
$link-text-decoration-hover: underline !default;

$body-background-color: #fff !default;
$body-background: $body-background-color url('../images/bg_lin.jpg') !default;
$primary-background-color: #fff !default;
$secondary-background-color: #f2f2f2 !default;

// Base variables used in alot calculations
$base-font-size: 18px !default;
$base-line-height: 1.4 !default;

// TINE variables - used in some common parts and should probably not be overridden
$tine-primary-background-color: #fff !default;
$tine-default-border-color: $primary-color !default;
$tine-default-border-style: solid !default;
$tine-default-border-width: 1px !default;
$tine-default-border: none;//$tine-default-border-width $tine-default-border-style $tine-default-border-color;

// Layout
$column-gutter: $base-line-height * $base-font-size !default;
$container-max-width: 1200px !default;

// Font
$base-font-family: 'Tisa Sans' !default;

// Parts
$content--title-align: center !default;
$content--author-font-size: 0.8rem !default;

$grid-brand--background: $primary-background-color !default;
$grid-brand--item-width-mobile: calc(100% / 4) !default;
$grid-brand--item-width-tablet: calc(100% / 6) !default;
$grid-brand--item-width-desktop: calc(100% / 9) !default;

$html-content--link-text-decoration: underline !default;
$html-content--link-text-decoration-hover: underline !default;
$html-content--link-text-color: $secondary-color !default;

$button--background: $secondary-background-color !default;
$button--text-color: $text-color !default;
