.grid-brand {
  padding-top: $column-gutter;
  background: $grid-brand--background;
}

.grid-brand__list {
  padding: 0 $column-gutter calc($column-gutter / 2) $column-gutter calc($column-gutter / 2);
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.grid-brand__item {
  flex: 0 1 auto;
  width: $grid-brand--item-width-mobile;

  @include tablet() {
    width: $grid-brand--item-width-tablet;
  }

  @include desktop() {
    width: $grid-brand--item-width-desktop;
  }
}

.grid-brand__link {
  text-align: center;
  display: block;
  padding: 10px;
  margin: 0 5px;
  border-bottom: $tine-default-border;
  transition: border-color 0.6s;

  &:hover {
    border-color: darken($tine-default-border-color, 20);
  }
}

.grid-brand__icon {
  height: 69px;
  width: 100%;
}

.grid-brand__icon--rasterized {
  height: 69px;
  max-width: 100%;
}
